@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Satoshi Medium';
  }
}

@font-face {
  font-family: 'Satoshi Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Satoshi Regular'), url('../public/assets/Satoshi-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Satoshi Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Satoshi Medium'), url('../public/assets/Satoshi-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Satoshi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Satoshi Bold'), url('../public/assets/Satoshi-Bold.woff') format('woff');
}